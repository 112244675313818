exports.components = {
  "component---src-components-recipe-index-js": () => import("./../../../src/components/RecipeIndex.js" /* webpackChunkName: "component---src-components-recipe-index-js" */),
  "component---src-components-recipe-js": () => import("./../../../src/components/Recipe.js" /* webpackChunkName: "component---src-components-recipe-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-wholesale-enquiry-js": () => import("./../../../src/pages/contact/wholesale-enquiry.js" /* webpackChunkName: "component---src-pages-contact-wholesale-enquiry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-stockists-js": () => import("./../../../src/pages/stockists.js" /* webpackChunkName: "component---src-pages-stockists-js" */),
  "component---src-pages-thanks-for-getting-in-touch-js": () => import("./../../../src/pages/thanks-for-getting-in-touch.js" /* webpackChunkName: "component---src-pages-thanks-for-getting-in-touch-js" */)
}

